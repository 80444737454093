import React, { useState, useEffect } from "react";
import {
    web3,
    web3getAccount,
    timeNows,
    userPendingReward,
    CheckBalance,
    chkApproveAmount,
    addApprove,
    Stake,
    Harvest,
    Unstake
} from "../web3/web3Provider";
import './../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button, InputGroup, FormControl, Spinner } from "react-bootstrap";
import { BoxArrowInDownRight, BoxArrowUpRight, BucketFill, Lock } from 'react-bootstrap-icons';
// import { Link } from "react-router-dom";
import Swal from 'sweetalert2'

function Pools(props) {

    const [timesNows, setTimesNows] = useState(0);
    const [maxStake, setmaxStake] = useState(0);
    // const [maxUnstake, setmaxUnstake] = useState(0);
    const [pbxIsApprove, setPbxIsApprove] = useState(0);
    const [pbxIsOldApprove, setPbxIsOldApprove] = useState(0);
    const [iconApproveLoading, setIconApproveLoading] = useState(false);
    const [iconStakeLoading, setIconStakeLoading] = useState(false);
    const [iconHarvestLoading, setIconHarvestLoading] = useState(false);
    const [iconUnstakeLoading, setIconUnstakeLoading] = useState(false);
    const [increaseApprove, setIncreaseApprove] = useState(false);

    const [pendingRewards, setPendingRewards] = useState(0);

    const timeConverter = (UNIX_timestamp) => {
        const addZero = (i) => {
            if (i < 10) { i = "0" + i }
            return i;
        }
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep.', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = addZero(a.getHours());
        var min = addZero(a.getMinutes());
        // var sec = addZero(a.getSeconds());
        var time = date + ' ' + month + ' ' + year + ' - ' + hour + ':' + min;
        return time;
    }

    const getBalance = async () => {
        await web3getAccount().then(async (res) => {
            await CheckBalance(res).then(async (balance) => {
                let _balance = parseFloat(web3.utils.fromWei(balance)).toFixed(3)
                setmaxStake(_balance)
            })
        })
    }

    const chkApproveAmounts = async (poolsId) => {
        await web3getAccount().then(async (res) => {
            await CheckBalance(res).then(async (balance) => {
                await chkApproveAmount(poolsId).then((approveAmount) => {
                    let PBXfromWei = parseFloat(web3.utils.fromWei(balance)).toFixed(3)
                    let PBXApprovefromWei = parseFloat(web3.utils.fromWei(approveAmount)).toFixed(3)

                    let preData = {"PoolsId": poolsId, "PBX": PBXfromWei, "PBXApprove": PBXApprovefromWei}

                    setPbxIsApprove(PBXfromWei)
                    setPbxIsOldApprove(PBXApprovefromWei)
                    if(PBXApprovefromWei < PBXfromWei){
                        console.log(preData)
                        console.log("Approve is LOW")
                        setIncreaseApprove(true)
                    }
                })
            })
        })
        
    }

    const setMaxStake = (poolsId) => {
        let inputPBXtoStake = document.querySelector("#inputPBXtoStake" + poolsId)
        inputPBXtoStake.value = Math.floor(maxStake).toFixed(2)
    }
    const setMaxUnstake = (poolsId) => {
        let inputPBXtoUnstake = document.querySelector("#inputPBXtoUnstake" + poolsId)
        inputPBXtoUnstake.value = parseFloat(props.stakerDeposit);

    }

    const callApprove = async (poolId) => {
        setIconApproveLoading(true)
        try{
            await web3getAccount().then(async (res) => {
                await CheckBalance(res).then(async (balanceFromWei) => {
                    await addApprove(poolId, balanceFromWei, res)
                })
            })
        }catch(err){
            setIconApproveLoading(false)
        }
    }


    const callStake = async (poolsId) => {
        setIconStakeLoading(true)
        let amount = document.querySelector("#inputPBXtoStake" + poolsId)

        if((amount.value).length === 0){
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: "Amount is not null"
            }).then(() => setIconStakeLoading(false), amount.value = "" )
            return
        }

        if(parseFloat(amount.value) > maxStake){
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: "Amount is more than balance of you approve PBX"
            }).then(() => setIconStakeLoading(false), amount.value = "" )
            return
        }

        if(parseFloat(amount.value) > pbxIsOldApprove){
            Swal.fire({
                icon: 'warning',
                title: '<h4 class="text-danger">Please inscrease Approve</h4>',
                // text: "Amount is more than balance of you approve PBX",
                html: `
                <p>You want to stake <b class="text-dark">${amount.value}</b> PBX</p>
                <p>But you approve is <b class="text-danger">${pbxIsOldApprove}</b> PBX</p>
                `
            }).then(() => setIconStakeLoading(false), amount.value = "" )
            return
        }

        try {
            await web3getAccount().then(async (res) => {
                await Stake(poolsId, amount.value, res).then(async (txn) => {
                    Swal.fire({
                        background: 'black',
                        width: 480,
                        timer: 10 * 1000,
                        html: `
                            <img src="img/stake.gif" width="100%">
                        `,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    }).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Stake ' + amount.value + ' PBX is done',
                            footer: 'TXN : <a href="https://bscscan.com/tx/'+ txn + '" target="_blank">View Transaction</a>'
                        }).then(() => setIconStakeLoading(false), window.location.reload())
                    })
                })
            })
        } catch (err) {
           
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: JSON.stringify(err.message)
            }).then(() =>  setIconStakeLoading(false) )
        }
    }

    const callHarvest = async (poolsId) => {
        setIconHarvestLoading(true)
        let amount = document.querySelector("#inputPBXtoStake" + poolsId)
        try {
            await web3getAccount().then(async (res) => {
                await Harvest(poolsId, res).then(async (txn) => {
                    Swal.fire({
                        background: 'black',
                        width: 480,
                        timer: 10 * 1000,
                        html: `
                            <img src="img/harvest-unstake.gif" width="100%">
                        `,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    }).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Harvest ' + amount.value + ' PBX is done',
                            footer: 'TXN : ' + txn
                        }).then(() => setIconHarvestLoading(false), window.location.reload())
                    })
                })
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: err
            }).then(() => setIconHarvestLoading(false))
        }
    }

    const callUnstake = async (poolsId) => {
        setIconUnstakeLoading(true)
        let amount = document.querySelector("#inputPBXtoUnstake" + poolsId)
        console.log(amount.value)
        try {
            await web3getAccount().then(async (res) => {
                await Unstake(poolsId, amount.value, res).then(async (txn) => {
                    Swal.fire({
                        background: 'black',
                        width: 480,
                        timer: 8 * 1000,
                        html: `
                            <img src="img/harvest-unstake.gif" width="100%">
                        `,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    }).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Unstake ' + amount.value + ' PBX is done',
                            footer: 'TXN : ' + txn
                        }).then(() => setIconUnstakeLoading(false), window.location.reload())
                    })
                })
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: err
            }).then(() => setIconUnstakeLoading(false))
        }
    }

    const fetchUserInfoOfPendingReward = async () => {
        await userPendingReward(props.poolsId).then((res) => {
            setPendingRewards(res) 
            // console.log(pendingRewards.pendingsRewards)
        })
    }

    useEffect(() => {
        const fetchPoolsInfo = async () => {
            await getBalance()
            await chkApproveAmounts(props.poolsId)
            await timeNows().then( async(res) => {
                setTimesNows(parseInt(res))
                console.log(res)
            })
        }
        fetchPoolsInfo()
    }, []);

    useEffect(() => {
        
        const updatePendingReward = setInterval(fetchUserInfoOfPendingReward, 3000)

        return () => {
            clearInterval(updatePendingReward)
        }
        
    }, pendingRewards);

    return (
        <>
            <Container>
                {
                    props.stakerDeposit > 0 || props.isApprove === true
                        ? (
                            <>
                                <Row>
                                    <Col lg="12" className="d-flex justify-content-center" style={{ padding: "10px 5px 10px 5px", borderLeft: "4px solid #d8dbe0", marginBottom: "10px", borderRadius: "5px" }}>
                                        <a href={ "https://bscscan.com/address/"+props.poolsAddress } >View Transaction</a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" className="d-flex justify-content-between goldenBack text-light" style={{ padding: "10px 5px 10px 5px", borderLeft: "4px solid #d8dbe0", marginBottom: "10px", borderRadius: "5px" }}>
                                        <span>My Staked</span>
                                        <span><span>{ props.stakerDeposit > 0.01 ? props.stakerDeposit : 0 }</span> PBX</span>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-between goldenBack text-light" style={{ padding: "10px 5px 10px 5px", borderLeft: "4px solid #d8dbe0", marginBottom: "10px", borderRadius: "5px" }}>
                                        <span>Days Unlock</span>
                                        <span><span>{props.stakerTimeLock === 0 ? 0 : timeConverter((props.stakerTimeLock*1000))}</span></span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <Card style={{ backgroundColor: "#eff6ff" }}>
                                            <Card.Body>
                                                <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>HARVEST</p>
                                                <span id="countPendingRewards">{parseFloat(pendingRewards.pendingsRewards).toFixed(6).toString().replace(/\B(?=(\d{6})+(?!\d))/g, ",")} PBX</span>
                                                <br /><br />
                                                {
                                                    pendingRewards.pendingsRewards > 0
                                                        ? (
                                                            <>
                                                                <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callHarvest(props.poolsId)} >
                                                                    {iconHarvestLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowInDownRight />{" "}</>)}
                                                                    HARVEST
                                                                </Button>
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                <Button variant="warning" className="goldenBack btnWeb3 w-100" disabled ><BucketFill /> HARVEST</Button>
                                                            </>
                                                        )
                                                }

                                                <p><small>0.1% fee for claim.</small></p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row >
                                <br />
                                <Row>
                                    <Col lg="6" style={{ marginBottom: "10px" }}>
                                        <Card style={{ backgroundColor: "#eff6ff" }}>
                                            <Card.Body>
                                                <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>STAKE</p>
                                                {
                                                    // props.poolsId === 3
                                                    // ?   (<>
                                                    //         <InputGroup className="mb-3">
                                                    //             <FormControl className="bg-dark text-light text-center btnWeb3" type="number" min="1" step="0.001" id={"inputPBXtoStake" + props.poolsId} placeholder="0" aria-describedby="basic-addon1" />
                                                    //             <Button variant="warning" className="goldenBack btnWeb3" onClick={() => setMaxStake(props.poolsId)} >MAX</Button>
                                                    //         </InputGroup>
                                                    //         <p>
                                                    //             <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callStake(props.poolsId)} >
                                                    //                 {iconStakeLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowInDownRight />{" "}</>)}
                                                    //                 STAKE
                                                    //             </Button>
                                                    //         </p>
                                                    //         <p>
                                                    //             <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callApprove(parseInt(props.poolsId))} >
                                                    //             {iconApproveLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><Lock />{" "}</>)}
                                                    //                 inscrease Approve
                                                    //             </Button>
                                                    //         </p>
                                                    //     </>)
                                                    // : (
                                                        (parseFloat(props.limitStake) === 0 || parseFloat(props.totalStaking).toFixed(2) < parseFloat(props.limitStake).toFixed(2))
                                                        ?   (<>
                                                            <InputGroup className="mb-3">
                                                                <FormControl className="bg-dark text-light text-center btnWeb3" type="number" min="1" step="0.001" id={"inputPBXtoStake" + props.poolsId} placeholder="0" aria-describedby="basic-addon1" />
                                                                <Button variant="warning" className="goldenBack btnWeb3" onClick={() => setMaxStake(props.poolsId)} >MAX</Button>
                                                            </InputGroup>
                                                            <p>
                                                                <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callStake(props.poolsId)} >
                                                                    {iconStakeLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowInDownRight />{" "}</>)}
                                                                    STAKE
                                                                </Button>
                                                            </p>
                                                            <p>
                                                                <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callApprove(parseInt(props.poolsId))} >
                                                                {iconApproveLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><Lock />{" "}</>)}
                                                                    inscrease Approve
                                                                </Button>
                                                            </p>
                                                        </>)
                                                        : (<>
                                                            <Button variant="warning" className="goldenBack btnWeb3 w-100" disabled >
                                                                <BoxArrowInDownRight />{" "}Pool Limit
                                                            </Button>
                                                        </>
                                                        )
                                                    // )
                                                    
                                                }

                                                <p><small>0% fee for staking.</small></p>
                                                <div className="d-flex justify-content-between text-danger" style={{
                                                    padding: "10px 5px 10px 5px",
                                                    borderLeft: "4px solid #d8dbe0", borderRadius: "5px", border: "1px solid red"
                                                }}>
                                                    <small style={{ marginLeft: "10px" }}> * The day after locked staking is re-counted</small>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg="6" style={{ marginBottom: "10px" }}>
                                        <Card style={{ backgroundColor: "#eff6ff" }}>
                                            <Card.Body>
                                                <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>UNSTAKE</p>
                                                <InputGroup className="mb-3">
                                                    <FormControl className="bg-dark text-light text-center btnWeb3" type="number" min="1" step="0.001" id={"inputPBXtoUnstake" + props.poolsId} placeholder="0" aria-describedby="basic-addon1" />
                                                    <Button variant="warning" className="goldenBack btnWeb3" onClick={() => setMaxUnstake(props.poolsId)} >MAX</Button>
                                                </InputGroup>
                                                {
                                                    // parseFloat(props.totalStaking) <= parseFloat(props.limitStake)
                                                    //     ? (
                                                            parseFloat(props.stakerTimeLock) !== 0 && parseFloat(timesNows) >= parseFloat(props.stakerTimeLock)
                                                                ? (<>
                                                                    <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callUnstake(parseFloat(props.poolsId))} >
                                                                        {iconUnstakeLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowUpRight />{" "}</>)}
                                                                        UNSTAKE
                                                                    </Button>
                                                                </>)
                                                                : (<>
                                                                    <Button variant="warning" className="goldenBack btnWeb3 w-100" disabled >
                                                                        {iconUnstakeLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowUpRight />{" "}</>)}
                                                                        UNSTAKE ( Days lock )
                                                                    </Button>
                                                                </>)
                                                        // )
                                                        // : (<>
                                                        //     <Button variant="warning" className="goldenBack btnWeb3 w-100" disabled >
                                                        //         <BoxArrowInDownRight />{" "}Pool Limit
                                                        //     </Button>
                                                        // </>)
                                                }
                                                <p><small>0.1% fee for unstaking.</small></p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        )
                        : (
                            <>
                                <Row>
                                    <Col lg="12">
                                        <Card style={{ backgroundColor: "#eff6ff" }}>
                                            <Card.Body>
                                                <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>Please Approve</p>
                                                <p>
                                                    <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => callApprove(parseInt(props.poolsId))} >
                                                        {iconApproveLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><Lock />{" "}</>)}
                                                        Approve
                                                    </Button>
                                                </p>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row >
                            </>
                        )
                }
                <br/>
                <Row>
                    <Col lg="12" className="d-flex justify-content-between goldenBack text-light" style={{ padding: "10px 5px 10px 5px", borderLeft: "4px solid #d8dbe0", marginBottom: "10px", borderRadius: "5px" }}>
                        <span style={{ marginLeft: "10px" }}>TVL Limit</span>
                        <span>
                            <span>
                                {
                                    props.limitStake > 0
                                        ? parseFloat(props.limitStake).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        : "Unlimit"
                                }
                            </span>
                            {' '} PBX</span>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className="d-flex justify-content-between goldenBack text-light" style={{ padding: "10px 5px 10px 5px", borderLeft: "4px solid #d8dbe0", borderRadius: "5px" }}>
                        <small style={{ marginLeft: "10px" }}> TVL :</small>
                        <small>{(parseFloat(props.totalStaking)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} PBX</small>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default Pools;