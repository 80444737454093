import React from 'react';
/*import Web3 from 'web3';
import { MemberInfo, CreateAccount, SendWCACALL, CheckBalance, web3getAccount, sendToken } from "../web3/web3Provider";*/
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Book } from 'react-bootstrap-icons';


const btnIsWeb3 = () => {
  return (
    <Link to='/list'>
      <Button 
        variant="warning" 
        className="goldenBack btnWeb3" 
        style={{ fontFamily: "Montserrat", 
        padding: "10px 25px" }} 
      >
        Connect Wallet
        </Button>{' '}
    </Link>
  );
}
const btnIsNotWeb3 = () => {
  return (
    <Link to={{ pathname: "https://metamask.io/download.html" }} target="_parent">
      <Button 
        variant="dark" 
        className="btnWeb3"
        style={{ fontFamily: "Montserrat", 
        padding: "10px 25px" }} 
      >
        <Image src="./metamask.png" /> Install Metamask
        </Button>{' '}
    </Link>
  );
}

export default class Home extends React.Component {

  render(){
  return (
    <>
          <div className="text-center">
            <header className="App-header">
              <p><img src="pbx.gif" alt="logo" /></p>
              <br/>
              <h3 style={{ letterSpacing: ".3em", fontFamily: "Montserrat", fontSize: "15px", textTransform: "uppercase", padding: "0px 5px 0px 5px" }}>
              Please connect your wallet for staking
              </h3>
              <br/>
              <p style={{ fontFamily: "Montserrat", fontSize: "15px", color: "rgb(90, 96, 108)"}}>Minimum staking time is 90 days.</p>
              {
                typeof window.ethereum === 'undefined'
                  ? btnIsNotWeb3()
                  : btnIsWeb3()
              }
              <p>
                <div style={{ marginTop: '25%'}}>
                  <p>
                    <Link to={{ pathname: "https://drive.google.com/file/d/1hfN31OdZLbfSGI6jwSs7sB1FYrgQr6YK/view?usp=sharing" }} target="_parent" style={{ textDecoration: 'none', fontSize: 18 }}><Book /> Manual ( English )</Link>
                  </p>
                  <p>
                    <Link to={{ pathname: "https://drive.google.com/file/d/1cR36aUqYHmZD1NOqtHwlyY0ERI8L2XIr/view?usp=sharing" }} target="_parent"  style={{ textDecoration: 'none', fontSize: 18 }}><Book /> คู่มือ ( ภาษาไทย )</Link>
                  </p>

                </div>
              </p>
            </header>
          </div>
    </>
      
        
    );
  }
}
